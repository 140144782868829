/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configBaumhoff: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53670,
        enable: true,
        projectName: 'Autohaus Baumhoff',
        searchPath: '/gebrauchtwagen/vw-audi-gebrauchtwagen-lennestadt.html',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"vw_textregular", "Open Sans", "Arial", sans-serif',
        fontSize: 16,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:focus': {
                                outline: 'none !important',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:active, &:hover, &.Mui-selected': {
                                outline: 'none !important',
                                textDecoration: 'none !important',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CalcFdTypeOptions_CalcFd"] label.MuiFormControlLabel-labelPlacementEnd': {
                                marginBottom: 0,
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"vw_headregular", ' + '<fontFamily>',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"vw_headregular", ' + '<fontFamily>',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"vw_headregular", ' + '<fontFamily>',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"vw_headregular", ' + '<fontFamily>',
                },
            },
        }),
        primaryColor: '#001e50',
    },
};

export default configBaumhoff;
