import { type ReactNode, createContext, useContext, useMemo } from 'react';
import type { ConfigObject } from '../_configs/types';

const SettingsContext = createContext<ConfigObject>(undefined);

export const useSettingsContext = () => {
    const context = useContext(SettingsContext);
    return context;
};


type SettingsProviderProps = {
    children: ReactNode;
    initSettings: ConfigObject;
};

export function SettingsProvider({ children, initSettings }: SettingsProviderProps) {
    const memoizedValue = useMemo(
        () => (initSettings),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return <SettingsContext.Provider value={memoizedValue}>
        {children}
    </SettingsContext.Provider>;
}
