/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getSessionStorage, HptSessionStorageSettings } from '../hooks/use-storage';
import { checkUndefinedNullOrEmpty } from '../utils/tools';
import merge from '../utils/tools/merge';
import configDefault from './_defaultValues';
import configShowRoooom from './_showroooom';
import { configHosts } from './hosts';
import { ConfigObject } from './types';

/**
 * Configuration object.
 *
 * @type {ConfigObject}
 * @example
 * {
    general: {
        idFirma: 13890,
        enable: true,
        metaTitleSuffix: 'Autohaus Liliensiek',
        searchPath: '/fahrzeugsuche',
    },
    contact: {
        isLeadSystem: true,
        linkDisclaimer: '/datenschutzerklaerung'
    },
    module: {
        aktionsslider: true,
        finanzierungsansicht: true,
        schnellsuche: true
    },
    fahrzeugsuche: {
        idExposee: 1572
    },
    style: {
        fontFamily: '"Muli", "Open Sans", "Arial", sans-serif',
        fontSize: 14,
        oldMuiTypography: {
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
        },
        primaryColor: '#0099da',
    },
}
 */

/**
 * Function to get array of href-variants.
 *
 * @function
 * @param {string} href - Host adress without www.
 * @param {boolean} dev - get dev hrefs.
 * @return {string}
 */
function getHostVariants(href: string | null, dev = false): string[] {
    let returnArray: string[] = [];

    if (href === 'local' && dev === true) {
        returnArray = returnArray.concat(
            getHostVariants('localhost', true),
            getHostVariants('192.168.100.22', true),
            getHostVariants('hpt-dev.autohaus-digital.de', true),
            getHostVariants('hpt-next.autohaus-digital.de', true),
            getHostVariants('hpt-pre-release.autohaus-digital.de', true),
            getHostVariants('hpt-test.autohaus-digital.de', true),
        );
    } else if (href !== 'local') {
        returnArray.push(href);
        const hrefNoTopLevelDomain = href.replace(/\.[^.]{2,3}$/g, '');
        if (dev === false && !checkUndefinedNullOrEmpty(hrefNoTopLevelDomain)) {
            returnArray.push(hrefNoTopLevelDomain + '.autohaus-digital.de');
            returnArray.push(hrefNoTopLevelDomain + '-dev.autohaus-digital.de');
            returnArray.push(hrefNoTopLevelDomain + '-next.autohaus-digital.de');
            returnArray.push(hrefNoTopLevelDomain + '-pre-release.autohaus-digital.de');
            returnArray.push(hrefNoTopLevelDomain + '-test.autohaus-digital.de');
            returnArray.push(hrefNoTopLevelDomain + '.devbite.me');
            returnArray.push(hrefNoTopLevelDomain + '.devbite.de');
        }
    }

    return returnArray;
}

/**
 * Function to get array of href-variants.
 *
 * @function
 * @param {string} expectedHost - Host adress without www.
 * @param {boolean} dev - get dev hrefs.
 * @return {string}
 */
function checkHost(expectedHost: string | null, dev = false): boolean {
    const hostsToFit = getHostVariants(expectedHost, dev);
    const check = hostsToFit?.some((host) => {
        const locationHost = location.pathname.startsWith('/install') ? location.search.replace('?host=', '') : location.host;
        return locationHost.replace(/:[0-9]{1,5}\//g, '/').includes(host);
    });
    return check;
}

/**
 * Function to get configuration according to the location.
 *
 * @function
 * @param {object} additionalConfig - additional config from html element.
 * @return {ConfigObject}
 */
function sConfigs(additionalConfig: Partial<ConfigObject> = {}): ConfigObject {
    const conf = configHosts?.find((c) => (
        c?.hosts?.some((host) => checkHost(host, c.isDev))
    ));

    const sessionStorage: HptSessionStorageSettings = JSON.parse(getSessionStorage('debug') || '{}');

    const configObject: ConfigObject = merge({}, configDefault, conf?.config, conf?.config?.general?.showroooom === true ? configShowRoooom : {}, additionalConfig, { general: { debug: sessionStorage?.debug || false, initialSearchPath: location?.href } });

    return configObject;
}

export default sConfigs;

