import isArray from 'lodash-es/isArray';
import mergeWith from 'lodash-es/mergeWith';

export default function merge(source: any, ...targets: any[]) {
    function customizer(objValue: any, srcValue: any) {
        if (isArray(objValue)) {
            return srcValue;
        }
    }

    return mergeWith(source, ...targets, customizer);
};
