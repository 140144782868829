/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configRudolph: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30150,
        enable: true,
        isGruppe: true,
        projectName: 'Autohaus Rudolph',
        searchPath: '/neuwagen/fahrzeugboerse',
        useOldRouter: true,
    },
    api: {
        ...configDefault.api,
        faaren: {
            ...configDefault.api.faaren,
            apiKey: '284|iyWvoJ9TrEdX3bPC4h5sTPvqeidI4A416rXBhMFnanbEtzB6yb3YM8vVXiyoaGNr',
            enabled: true,
        },
        tracking: {
            ...configDefault.api.tracking,
            customScripts: {
                ...configDefault.api.tracking.customScripts,
                commonSuccess: true,
                formCommonSuccess: 'gtag(\'event\', \'conversion\', {\'send_to\':\'AW-1044458664/VlLQCLvS3MgZEKjZhPID\',\'value\':50.0,\'currency\':\'EUR\'});',
            },
        },
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
        blacklistLeadsystem: [52080, 53810],
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 14,
        },
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        fahrzeugslider: true,
        schnellsuche: true,
    },
    aktionsslider: {
        ...configDefault.aktionsslider,
        forcedFilter: {
            ...configDefault.aktionsslider.forcedFilter,
            ignoreFirma: [52080, 53810],
        },
    },
    fahrzeugslider: {
        ...configDefault.fahrzeugslider,
        forcedFilter: {
            ...configDefault.fahrzeugslider.forcedFilter,
            ignoreFirma: [52080, 53810],
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            ignoreFirma: [52080, 53810],
        },
        limitCols: 3,
        topFilter: true,
        tradeInLink: 'https://www.vw-rudolph.com/neuwagen/fahrzeugankauf',
        useEyecatcher: true,
    },
    schnellsuche: {
        ...configDefault.schnellsuche,
        forcedFilter: {
            ...configDefault.schnellsuche.forcedFilter,
            ignoreFirma: [52080, 53810],
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0.75,
        fontSize: 14,
        fontFamily: '"Open Sans", "Helvetica", "Arial", "sans-serif"',
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    contained: {
                        border: '1px solid <colorPrimary>',
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'background': '#fff',
                            'border': '1px solid <colorPrimary>',
                            'color': '<colorPrimary>',
                            '&:hover': {
                                background: '#eff1f4',
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            marginBottom: 0,
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="vehicleEinzelansicht"] div.MuiStack-root[data-testid="CarPriceGrid_Container"] p.MuiTypography-h5': {
                                fontWeight: 600,
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                    inputMultiline: {
                        [getStyleQuery()]: {
                            '&[name="fd"]': {
                                background: 'transparent',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.FahrzeugSucheFilterSort_Container > button.MuiButton-text svg': { // disable "Raster"/"Liste" SVGs, not enough space, overflow with flex-shrink 0
                                display: 'none',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'outline': 'none !important',
                            '&:hover': {
                                textDecoration: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontWeight: 400,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Titillium Web", "Helvetica", "Arial", "sans-serif"',
                    fontWeight: 300,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Titillium Web", "Helvetica", "Arial", "sans-serif"',
                    fontWeight: 300,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Titillium Web", "Helvetica", "Arial", "sans-serif"',
                    fontWeight: 300,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Titillium Web", "Helvetica", "Arial", "sans-serif"',
                    fontWeight: 600,
                },
            },
        }),
        primaryColor: '#345072',
    },
};

export default configRudolph;
