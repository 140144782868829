import isEqual from 'lodash-es/isEqual';
import { useCallback, useState } from 'react';
import { ConfigObject } from '../_configs/types';
import { Car, Filter, ViewOptionsView } from '../_types/Car';
import { checkUndefined } from '../utils/tools';

// ----------------------------------------------------------------------

const LOCAL_STORAGE_SETTINGS = 'hpt:storage';

type HptLocalStorageSettings = {
    allowMaps: boolean,
    auth: string | null,
    listMode: ConfigObject['fahrzeugsuche']['listView'],
};

export type HptSessionStorageSettings = {
    debug: boolean,
    lastViewedCar: Car<false>['id'] | null,
    search: Filter,
};

// ----------------------------------------------------------------------

export const getLocalStorage = <T>(key: string): T => {
    let value = null;

    try {
        const result = typeof window !== 'undefined' ? window.localStorage.getItem(key) : undefined;

        if (!checkUndefined(result)) {
            value = JSON.parse(result);
        }
    } catch (error) {
        console.error(error);
    }

    return value;
};

export const setLocalStorage = <T>(key: string, value: T) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(error);
    }
};

export const removeLocalStorage = (key: string) => {
    try {
        window.localStorage.removeItem(key);
    } catch (error) {
        console.error(error);
    }
};

// ----------------------------------------------------------------------

export const getSessionStorage = <T>(key: string): T => {
    let value = null;

    try {
        const result = typeof window !== 'undefined' ? window.sessionStorage.getItem(key) : undefined;

        if (!checkUndefined(result)) {
            value = JSON.parse(result);
        }
    } catch (error) {
        console.error(error);
    }

    return value;
};

export const setSessionStorage = <T>(key: string, value: T) => {
    try {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(error);
    }
};

export const removeSessionStorage = (key: string) => {
    try {
        window.sessionStorage.removeItem(key);
    } catch (error) {
        console.error(error);
    }
};

// ----------------------------------------------------------------------

const defaultLocalStorage: HptLocalStorageSettings = { allowMaps: false, auth: null, listMode: ViewOptionsView.Grid };
let timerLocalStorage: NodeJS.Timeout;

export function useLocalStorage<F extends keyof HptLocalStorageSettings>(field: F) {
    const [state, setState] = useState<HptLocalStorageSettings>(getLocalStorage<HptLocalStorageSettings>(LOCAL_STORAGE_SETTINGS) || defaultLocalStorage);

    const updateState = useCallback((updateValue: HptLocalStorageSettings[F]) => {
        setState((prevValue: HptLocalStorageSettings) => {
            const newSettings = { ...prevValue };
            const newValue = updateValue || null;
            if (!isEqual(prevValue?.[field], newValue)) {
                newSettings[field] = newValue;
                clearTimeout(timerLocalStorage);
                timerLocalStorage = setTimeout(() => {
                    setLocalStorage<HptLocalStorageSettings>(LOCAL_STORAGE_SETTINGS, newSettings);
                }, 250);
            }
            return newSettings;
        });
    }, [field]);

    return {
        state: state?.[field],
        update: updateState,
    };
}


// ----------------------------------------------------------------------

const defaultSessionStorage: HptSessionStorageSettings = { debug: false, lastViewedCar: null, search: null };
let timerSessionStorage: NodeJS.Timeout;


export function useSessionStorage<F extends keyof HptSessionStorageSettings>(field: F) {
    const [state, setState] = useState<HptSessionStorageSettings>(getSessionStorage<HptSessionStorageSettings>(LOCAL_STORAGE_SETTINGS) || defaultSessionStorage);

    const updateState = useCallback((updateValue: HptSessionStorageSettings[F]) => {
        setState((prevValue: HptSessionStorageSettings) => {
            const newSettings = { ...prevValue };
            const newValue = updateValue || null;
            if (!isEqual(prevValue?.[field], newValue)) {
                newSettings[field] = newValue;
                clearTimeout(timerSessionStorage);
                timerSessionStorage = setTimeout(() => {
                    setSessionStorage<HptSessionStorageSettings>(LOCAL_STORAGE_SETTINGS, newSettings);
                }, 250);
            }
            return newSettings;
        });
    }, [field]);

    return {
        state: state?.[field],
        update: updateState,
    };
}
