/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Bastian Reichelt
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject, TrackingProvider } from '../types';

const configOutletCars: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54630,
        enable: true,
        projectName: 'OutletCars',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        isCatchLeadSystem: true,
        catchCustomFields: {
            lead_channel: 'Formular',
            lead_source: 'OutletCars',
            lead_source2: 'OutletCars',
            marketing_campaign: 'OutletCars',
            urlWithoutProtocol: true,
        },
        useTextInsteadOfPrivacyCheckBox: 'Wenn Sie uns über das Kontaktformular ansprechen, d.h. durch Klick auf den nachfolgenden Button Ihre Anfrage übersenden, werden wir Ihre Angaben aus dem Formular zum Zweck der Bearbeitung Ihrer Anfrage bzw. Kontaktaufnahme verwenden. Eine Verarbeitung Ihrer Daten zu anderen Zwecken oder eine Weiterleitung an Dritte findet nicht statt. Die Rechtsgrundlage für die Verarbeitung Ihrer Daten ergibt sich aus Art. 6 (1) lit b. DSGVO. Unsere vollständige Datenschutzerklärung finden Sie hier:',
    },
    api: {
        ...configDefault.api,
        tracking: {
            ...configDefault.api.tracking,
            apiKey: 'fnxB09',
            customScripts: {
                ...configDefault.api.tracking.customScripts,
                commonSuccess: true,
                formCommonSuccess: 'gtag(\'event\', \'conversion\', {\'send_to\':\'AW-11323378867/JAp2CJDpppcZELOZtJcq\'});',
                tradeInClick: 'gtag(\'event\', \'conversion\', {\'send_to\':\'AW-11323378867/QornCIrpppcZELOZtJcq\',\'transaction_id\':\'\'});',
            },
            enabled: true,
            provider: TrackingProvider.ETracker,
        },
    },
    cartile: {
        ...configDefault.cartile,
        infoGrid: {
            ...configDefault.cartile.infoGrid,
            KRAD: ['ez', 'km', '[L]ident', 'engine.leistung', 'engine.motor', 'getriebe', '[L]siegel', '[L]farbe', '[L]antrieb', 'spacer'],
            default: ['ez', 'km', '[L]ident', 'engine.leistung', 'engine.motor', 'getriebe', '[L]siegel', '[L]farbe', '[L]antrieb', 'spacer'],
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['sofort', 'typ', 'hersteller', 'modell', 'aufbau', 'ez', 'motor', 'getriebe', 'aus', 'preis', 'rate', 'standort', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]aufbau', '[LG]ez', 'motor', 'getriebe', 'standort', 'aus', 'preis', 'rate', 'typ', 'tags', 'sofort'],
        },
        hideFooterVerbrauch: true,
        noAnimations: true,
        tradeInLink: '/auto-verkaufen',
    },
    fahrzeugdetail: {
        ...configDefault.fahrzeugdetail,
        infoGrid: {
            KRAD: ['ident', 'typ', 'ez', 'km', 'engine.motor', 'getriebe', 'engine.leistung', 'farbe', 'engine.hubraum', 'standort', 'siegel_garantie', 'antrieb'],
            default: ['ident', 'typ', 'ez', 'km', 'engine.motor', 'getriebe', 'engine.leistung', 'farbe', 'tueren', 'sitze', 'siegel', 'antrieb'],
        },
    },
    schnellsuche: {
        ...configDefault.schnellsuche,
        buttonBelowFilters: true,
        filter: ['hersteller', 'modell', 'motor', 'preisBis', 'ezVon'],
        limitCols: 3,
        noAnimations: true,
    },
    aktionsslider: {
        ...configDefault.aktionsslider,
        noAnimations: true,
    },
    erweiterteSchnellsuche: {
        ...configDefault.erweiterteSchnellsuche,
        noAnimations: true,
    },
    fahrzeugslider: {
        ...configDefault.fahrzeugslider,
        noAnimations: true,
    },
    finanzierungsansicht: {
        ...configDefault.finanzierungsansicht,
        noAnimations: true,
    },
    style: {
        ...configDefault.style,
        fontFamily: '"dmsans-regular"',
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.03em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                },
            },
        }),
        noAnimations: true,
        primaryColor: '#ff0000',
    },
};

export default configOutletCars;
