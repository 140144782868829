import { Translations } from './translations';

const frFR: Translations = {
    buttons: {
        acceptMaps: 'Cliquez ici pour accepter l\'utilisation de Google Maps!',
        advancedSearch: 'Recherche avancée',
        back: 'Retour',
        backToSearch: 'Retour à la recherche',
        chooseVehicleHeading: 'Choisir un véhicule',
        clear: 'Vider',
        close: 'Fermer',
        collapse: 'Fermer',
        expand: 'Déplier',
        filters: 'Filtres',
        loadingView: 'Chargement de la vue...',
        loadMoreResults: 'Chargement d\'autres résultats',
        loadMoreVehicles: 'Chargement d\'autres véhicules...',
        nextPage: 'Suivant',
        no: 'Non',
        prevPage: 'Page précédente',
        rateCalculator: 'Calculateur de mensualités',
        resetFilter: 'Réinitialiser le filtre',
        search: 'Recherche',
        showAllVehicles: 'Afficher tous les {0} véhicules',
        vehicle: 'Véhicule',
        vehicles: 'Véhicules',
    },
    car: {
        ez: 'Première immatriculation',
        km: 'Kilométrage',
        ident: 'Numéro de commande à Référence',
        kategorie: 'Catégorie',
        typ: 'Type de véhicule',
        types: {
            singular: {
                '-': '(inconnu)',
                '1': 'Véhicule neuf',
                '2': 'Véhicule d\'occasion',
                '4': 'Véhicule de démonstration',
                '8': 'Véhicule de location',
                '16': 'Véhicule spécial',
                '32': 'Véhicule immatriculé pour la journée',
            },
            plural: {
                '-': '(inconnu)',
                '1': 'Véhicules neufs',
                '2': 'Véhicules d\'occasion',
                '4': 'Véhicules de démonstration',
                '8': 'Véhicules de location',
                '16': 'Véhicules spéciaux',
                '32': 'immatriculations journalières',
            },
        },
        sitze: 'Sièges',
        tueren: 'Portes',
        engine: {
            effizienz: 'Efficacité',
            hubraum: 'Cylindrée',
            leistung: 'Puissance',
            motor: 'Moteur',
            verbrauch: {
                '0': 'Consommation combinée',
                '1': 'Émissions de CO2',
                '2ndDrive': '2ème propulsion',
                'combined': 'Combiné',
                'effizienz': 'Efficacité',
                'schadstoff': 'Norme Euro',
                'heading': 'Consommation et émissions',
                'inner': 'En ville',
                'outer': 'Hors agglomération',
                'slow': 'Centre-ville',
                'medium': 'Périphérie',
                'fast': 'Route',
                'veryFast': 'Autoroute',
                'range': 'Portée',
                'city': 'Ville',
                'protocol': 'Consommation',
                'protocolBy': 'Consommation après {0}',
                'nefz': 'NEDC',
                'wltp': 'WLTP',
                'noNEFZAvailable': 'Aucune valeur NEDC disponible',
            },
        },
        garantie: 'Garantie',
        getriebe: 'Boîte de vitesses',
        siegel: 'Sceau',
        standort: 'Emplacement',
        farbe: 'Couleur',
        antrieb: 'Transmission',
        verfuegbarkeit: 'Disponibilité',
        verfuegbarAb: 'Disponible à partir de',
        aktion: 'Véhicule d\'action',
        nowAvailable: 'disponible immédiatement',
        lieferzeit: 'Délai de livraison',
        preis: {
            inclMwst: 'TVA {0} incluse',
            exclMwst: 'TVA non affichable',
        },
        finanzierung: {
            fd: 'Service financier',
            title: 'Financement',
            desc: 'Avec AutoCredit, les mensualités sont particulièrement basses grâce à une mensualité finale plus élevée. À la fin du contrat, vous pouvez restituer le véhicule dans un état conforme au contrat chez le concessionnaire qui vous l\'a livré et qui règle alors la mensualité finale pour vous. Ce droit de restitution est même expressément attesté par votre concessionnaire automobile - raison pour laquelle il ne peut bien sûr s\'appliquer qu\'à lui. Vous pouvez également régler vous-même le paiement final ou continuer à le financer.',
            onRequest: 'sur demande',
            laufzeit: 'Durée',
            anzahlung: 'Acompte',
            rate: 'Taux',
            monate: 'mois',
            monatlich: 'par mois',
            effJahreszins: 'Taux d\'intérêt eff. taux d\'intérêt annuel',
            sollzinssatz: 'Taux d\'intérêt débiteur',
            schlussrate: 'Taux final',
            ksb: 'Assurance protection crédit',
            ksb_betrag: 'Montant assurance protection crédit',
            laufleistung: 'Kilométrage annuel',
            nettokreditbetrag: 'Montant net du crédit',
            bruttokreditbetrag: 'Montant brut du crédit',
            inklTransfer: 'frais de transfert inclus',
            netto: 'net',
            brutto: 'brut',
            angebot: 'Une offre de',
            noProducts: 'Aucune offre disponible pour ce véhicule',
            noOffer: 'Aucune offre trouvée',
            angebotAnfragen: 'Demander une offre',
            individuellBerechnen: 'Calculer maintenant individuellement',
            bonitat: 'Sous réserve de solvabilité',
        },
        leasing: {
            title: 'Leasing',
            desc: 'Une nouvelle voiture sans engagement à long terme ni risque financier ? - Des taux de leasing calculables pour la durée de leasing de votre choix, de 12 à 48 mois.',
            onRequest: 'sur demande',
            notAvailable: 'non disponible',
            typ: 'Type',
            commercial: 'commercial',
            commercialDesc: 'Pourquoi investir dans une voiture un capital qui pourrait être investi de manière bien plus lucrative dans l\'objectif de l\'entreprise ? Le leasing de véhicules d\'entreprise est une offre intéressante pour les clients professionnels qui souhaitent des mensualités peu élevées et qui veulent utiliser la voiture plutôt que de l\'acheter. Vous "louez" un véhicule de votre choix pour une période déterminée et payez des mensualités de leasing peu élevées. A la fin du contrat, vous rendez simplement votre voiture à votre concessionnaire après l\'avoir utilisée conformément au contrat et en prenez une nouvelle en leasing.',
            private: 'privé',
            privateDesc: 'Le leasing privé est une offre intéressante pour les clients qui souhaitent de faibles mensualités et qui veulent utiliser la voiture plutôt que de l\'acheter. Grâce à la faible mensualité de leasing, il reste une marge de manœuvre financière pour d\'autres souhaits. A la fin du contrat, vous rendez simplement votre voiture à votre concessionnaire après l\'avoir utilisée conformément au contrat et en prenez une nouvelle en leasing.',
            laufzeit: 'Durée',
            monate: 'mois',
            monatlich: 'par mois',
            rate: 'Taux',
            effJahreszins: 'Taux d\'intérêt eff. Taux d\'intérêt annuel',
            sollzinssatz: 'Taux d\'intérêt débiteur',
            anzahlung: 'Paiement spécial',
            laufleistung: 'Kilométrage annuel',
            zulassungskosten: 'Frais d\'immatriculation',
            transferkosten: 'Frais de transfert',
            mehrkilometer: 'Plusdekilomètres',
            minderkilometer: 'Kilomètres en moins',
            nettokreditbetrag: 'Montant net du prêt',
            bruttokreditbetrag: 'Montant total',
            zzglTransfer: 'plus frais de transfert',
            lrv: 'LRV',
            netto: 'net',
            brutto: 'brut',
            angebot: 'Une offre de',
            bonitat: 'Sous réserve de solvabilité',
        },
        aus: {
            categories: {
                kopf: '',
                highlights: 'Highlights',
                technik: 'Technique et sécurité',
                multimedia: 'Multimédia',
                assistenz: 'Systèmes d\'assistance',
                innen: 'Equipement intérieur',
                sitze: 'Sièges et garnitures',
                aussen: 'Equipement extérieur',
                scheinwerfer: 'Phares et feux',
                raeder: 'Roues et pneus',
                pakete: 'Paquets',
                sonstiges: 'Autres',
                fussnoten: 'Notes de bas de page',
                fuss: '',
            },
            assistenz: {
                title: 'Systèmes d\'assistance',
                25: 'Régulateur de vitesse (GRA)',
                26: 'Aide au stationnement (APS)',
                48: 'Assistant de stationnement',
                332: 'Régulateur de distance',
                402: 'Caméra de recul',
                514: 'Assistant de changement de voie',
                515: 'Assistant de maintien de voie',
                1145: 'Reconnaissance des panneaux de signalisation',
            },
            aussen: {
                title: 'Equipement extérieur',
                34: 'Toit ouvrant',
                41: 'Phares au xénon',
                71: 'Jantes en alliage léger',
                88: 'Attelage de remorque',
                238: 'Toit panoramique / vitré',
                1140: 'Phares à LED',
            },
            innen: {
                title: 'Equipement intérieur',
                22: 'Climatisation automatique',
                23: 'Chauffage auxiliaire',
                24: 'Système de navigation',
                27: 'lève-vitres électriques',
                29: 'Sièges chauffants',
                36: 'Sièges électriques',
                81: 'Equipement en cuir',
                278: 'Sièges sport',
                1119: 'Bluetooth',
            },
            sonstiges: {
                title: 'Autres',
                61: 'Quatre roues motrices',
            },
        },
    },
    carSearch: {
        foundCar: 'Véhicule trouvé',
        foundCars: 'Véhicules trouvé',
        forFilters: 'pour filtre',
        gridView: 'Carreaux',
        heading: 'Véhicules neufs et d\'occasion',
        heading2: 'Véhicules d\'occasion',
        listView: 'Liste',
        page: 'Page',
        sort: 'Tri',
        view: 'Vue',
    },
    carView: {
        accessoires: {
            special: 'Équipement de spécial',
            standard: 'Équipement de série',
        },
        contactTab: {
            approach: 'Plan d\'accès',
            call: 'Appeler',
            mail: 'Mail',
            message: 'Demande',
            phone: 'Téléphone',
        },
        contact: {
            abort: 'Annuler',
            title: 'Contact',
            contactPerson: 'Votre contact',
            copyTel: {
                error: 'Erreur',
                success: 'Copié',
            },
            fd: 'Votre service financier',
            requestType: 'Type de demande',
            requestTypes: {
                '0': 'Envoyer un demande',
                '0a': 'Envoyer un demande à',
                '10': 'Demander un rendez-vous',
                '10a': 'Demander un rendez-vous chez',
                '12': 'Demander un rappel',
                '12a': 'Demander un rappel par',
                '20': 'Demande d\'essai',
                '20a': 'Demande d\'essai chez',
                '27': 'Prise de paiement',
                '27a': 'Prise de paiement chez',
                '0Short': 'demande',
                '10Short': 'Rendez-vous',
                '12Short': 'Rappel',
                '20Short': 'Essai de conduite',
                '27Short': 'Prise de paiement',
            },
            name: 'Nom',
            mail: 'Mail',
            phone: 'Téléphone',
            date: 'Date souhaitée',
            message: 'Votre demande',
            disclaimer: 'J\'ai lu le {0}',
            disclaimerLink: 'Politique de confidentialité',
            reset: 'Reprendre au début',
            send: 'Envoyer un demande',
            sending: 'Envoyer...',
            errorSending: 'Erreur lors de l\'envoi du demande',
            successSending: 'Demande envoyé avec succès',
            tryAgain: 'Veuillez réessayer dans quelques minutes',
            subjectPrefix: 'Demande de véhicule',
            subjectPrefixAbo: 'Demande de AutoAbo',
            errors: {
                reCaptcha: 'Echec de la vérification ReCAPTCHA',
                requestType: 'Type de demande manquant',
                name: 'Nom manquant',
                mailMissing: 'Mail manquant',
                mailInvalid: 'Mail dans un format incorrect',
                phone: 'Numéro de téléphone manquant',
                date: 'Date souhaitée manquante',
                message: 'Votre demande est manquant',
                acceptDisclaimer: 'Veuillez accepter la politique de confidentialité',
            },
        },
        finLeas: {
            errors: {
                downPayment: 'Acompte manquant',
                duration: 'Durée manquant',
                mileage: 'Kilométrage annuel manquant',
                rsv: 'LRV manquant',
            },
        },
        newTabs: {
            0: {
                title: 'Accessoires',
                url: 'accessoires',
            },
            1: {
                title: 'Description',
                url: 'description',
            },
            2: {
                title: 'Consommation indiquée',
                url: 'consommation-indiquee',
            },
            3: {
                title: 'Emplacement',
                url: 'emplacement',
            },
        },
        calculator: {
            button: 'Demandez maintenant pour {0} / mois',
            fields: {
                annualPercentageRate: 'Taux d\'intérêt annuel',
                carPrice: 'prix du véhicule',
                damage_participation: 'Franchise',
                delivery_option: {
                    faaren_delivery: 'Livraison par Faaren',
                    no_delivery: 'pas de livraison',
                    partner_delivery: 'livraison par le fournisseur',
                    title: 'Options de livraison',
                },
                delivery_time_after_booking: 'Délai de livraison après la commande',
                downPaymentFinancing: 'Acompte',
                downPaymentLeasing: 'Paiement spécial',
                duration: 'Durée',
                extraMileageCosts: 'Plusdekilomètres',
                fixed_duration: 'changeable tous les mois',
                lastRate: 'Taux final',
                leasePaymentInsurance: {
                    NONE: 'non',
                    RSV: 'AML',
                    RSVPLUS: 'AML Plus',
                    title: 'Assurance mensualités de leasing',
                },
                lowMileageCompensation: 'Kilomètres en moins',
                mileage: {
                    autoAbo: 'Kilométrage mensuel',
                    other: 'Kilométrage annuel',
                },
                min_license_duration: 'Durée minimale de détention du permis de conduire',
                minimum_age: 'Âge minimum',
                monthly: 'par mois',
                monthlyRate: 'Taux mensuel brut',
                monthlyRateNet: 'Taux mensuel net',
                netLoan: 'Montant net du prêt',
                nominalInterestRate: 'Taux d\'intérêt débiteur',
                paymentProtectionInsurance: {
                    NONE: 'non',
                    RSV: 'APC',
                    RSVPLUS: 'APC Plus',
                    title: 'Assurance protection crédit',
                },
                paymentProtectionInsuranceAmount: 'Montant assurance protection crédit',
                pick_up_option: {
                    faaren_delivery: 'Enlèvement par Faaren',
                    no_delivery: 'pas de livraison',
                    partner_delivery: 'Enlèvement par le fournisseur',
                    title: 'Options d\'enlèvement',
                },
                privateLeasing: 'privé',
                businessLeasing: 'entreprise',
                transfer: 'Frais de transport',
                total: 'Montant total',
                type: {
                    autoAbo: {
                        detail0: 'Tout compris, sauf le carburant',
                        detail1: 'Résiliation mensuelle possible à la fin de la durée minimale',
                        title: 'Auto-Abo',
                        prevText: 'louer',
                    },
                    financing: {
                        detail0: 'Mensualité faible',
                        detail1: 'Mensualité finale avec plusieurs options de souscription',
                        title: 'Financement',
                        prevText: 'financent',
                    },
                    leasing: {
                        business: 'Leasing d\'entreprise',
                        detail0: 'Mensualité fixe',
                        detail1: 'Restitution du véhicule à la fin du contrat',
                        private: 'Leasing privé',
                        title: 'Leasing',
                        prevText: 'lease',
                    },
                    title: 'Type',
                },
                upe: 'Prix neuf du véhicule',
            },
            title: {
                block: 'Financement ou leasing simple et flexible',
                blockWithAbo: 'Financement, leasing ou s\'abonner simple et flexible',
                rateSettings: 'Tes paramètres de mensualités',
                summary: {
                    financing: 'Votre offre de financement',
                    leasing: 'Votre offre de financement',
                    autoAbo: 'Votre offre de Auto-Abo offer',
                },
                typeChooser: 'Choisissez votre poison',
            },
        },
        downloadExposee: 'Télécharger',
        exposeeError: 'Une erreur est survenue lors de la génération!\nVeuillez réessayer plus tard',
        exposeeLoading: 'Un instant s\'il vous plaît, l\'exposé est généré',
        exposeeReady: 'L\'exposé est maintenant disponible pour le téléchargement',
        locationUsers: 'Votre contact',
        locationUsersContact: 'Prendre contact',
        share: {
            copy: 'Copier le lien',
            mail: 'Mail',
            title: 'Partagar',
        },
        showExposee: 'Exposé',
        similarCars: 'Véhicules similaires',
        tabs: {
            0: 'Données du véhicule',
            1: 'Description',
            2: 'Emplacement',
        },
        tradeIn: {
            heading: 'Prise de paiement?',
            content: 'Vous souhaitez reprendre votre véhicule actuel et obtenir rapidement une valeur marchande sans engagement?',
            button: 'Calculer maintenant',
        },
        verbrauch: {
            combined: 'Combiné',
            combinedWeighted: 'Combiné (pondéré)',
            combinedWeightedShort: 'Comb. pond.',
            co2class: 'Classe de CO2',
            co2classWeighted: 'Classe de CO2 (pondéré)',
            co2costs: 'Coûts CO2 possibles sur les 10 prochaines années (à 15.000 km / an):',
            co2costsLow: 'faible prix du CO2',
            co2costsMedium: 'prix moyen du CO2',
            co2costsHigh: 'prix élevé du CO2',
            discharged: 'lorsque la batterie\nest déchargée',
            energycosts: 'Coûts énergétiques par an (à 15.000 km / an)',
            emission: 'Émissions',
            emissionWeighted: 'Émissions (pondéré)',
            emissionWeightedShort: 'Émiss. pond.',
            euronorm: 'Norme Euro',
            extraHigh: 'Autoroute',
            header: 'Consommation de carburant',
            headerDischarged: 'Consommation de carburant lorsque la batterie est déchargée',
            headerElectro: 'Consommation électrique',
            headerPlugIn: 'Consommation électrique en mode purement électrique',
            high: 'Route',
            medium: 'Périphérie',
            motor: 'Motor',
            low: 'Centre-ville',
            range: 'Portée électrique',
            title: 'Consommation et émissions',
            weighted: 'pondéré',
        },
    },
    common: {
        and: 'et',
        at: 'auprès',
        brutto: 'brut',
        in: 'en',
        netto: 'net',
        notAvailable: 'non disponible',
    },
    errorHandler: {
        moduleDisabled: {
            heading: 'Désolé, mais le module n\'est pas disponible pour le moment!',
            subtitle: 'Vous êtes l\'exploitant? Dans ce cas, veuillez vous adresser à l\'administrateur.',
        },
        noResults: {
            headingAktionsslider: 'Aucun Véhicules d\'action n\'est actuellement disponible!',
            heading: 'Actuellement, aucun véhicule n\'est disponible pour cette recherche!',
            subtitle: 'Veuillez réessayer avec d\'autres paramètres de recherche.',
        },
        error: {
            heading: 'Désolé, une erreur est survenue!',
            subtitle: 'Veuillez réessayer plus tard.',
            tooltip: 'Afficher les détails de l\'erreur',
            actions: {
                exitError: {
                    tooltip: 'Cacher l\'erreur',
                },
                goHome: {
                    tooltip: 'Retour à la page d\'accueil',
                },
                reloadPage: {
                    tooltip: 'Recharger la page',
                },
            },
        },
        sIntErrors: {
            'webcalc': {
                title: 'Erreur',
                details: 'Malheureusement, une erreur s\'est produite lors de la demande des données de calcul. Veuillez réessayer plus tard!',
            },
            'unknown': {
                title: 'Erreur inconnue',
                details: 'Aucun détail d\'erreur disponible.',
            },
            'totalausfall': {
                title: 'Dysfonctionnement',
                details: 'Chers visiteurs, il y a actuellement un dysfonctionnement.\nVeuillez réessayer plus tard.',
            },
            'networkError': {
                title: 'Erreur de réseau',
                details: 'Une erreur de réseau est survenue. Veuillez vérifier votre connexion Internet!',
            },
            'runtimeError': {
                title: 'Erreur d\'exécution',
                details: 'Une erreur d\'exécution s\'est produite. Veuillez réessayer plus tard!',
            },
            '0x400': {
                title: 'Mauvaise requête',
                details: '',
            },
            '0x401': {
                title: 'Non autorisé',
                details: '',
            },
            '0x403': {
                title: 'Forbidden',
                details: '',
            },
            '0x404': {
                title: 'Not Found',
                details: '',
            },
            '0x405': {
                title: 'Méthode non autorisée',
                details: '',
            },
            '0x408': {
                title: 'Délai d\'attente de la requête',
                details: '',
            },
            '0x409': {
                title: 'Conflit',
                details: '',
            },
            '0x410': {
                title: 'Gone',
                details: '',
            },
            '0x414': {
                title: 'URI Trop long',
                details: '',
            },
            '0x415': {
                title: 'Type de média non désiré',
                details: '',
            },
            '0x423': {
                title: 'Verrouillé',
                details: '',
            },
            '0x425': {
                title: 'Trop tôt',
                details: '',
            },
            '0x429': {
                title: 'Trop de requêtes',
                details: '',
            },
            '1x404': {
                title: 'Données de la société non trouvées',
                details: '',
            },
            '1x500': {
                title: 'Erreur lors de la récupération des données de l\'entreprise',
                details: '',
            },
            '2x404': {
                title: 'Véhicules non trouvé',
                details: '',
            },
            '2x500': {
                title: 'Erreur lors de la récupération de la liste des véhicules',
                details: '',
            },
            '3x204': {
                title: 'Véhicule non trouvé',
                details: '',
            },
            '3x404': {
                title: 'Véhicule non trouvé',
                details: '',
            },
            '3x500': {
                title: 'Erreur lors de la récupération des données du véhicule',
                details: '',
            },
            '3x501': {
                title: 'Erreur lors de la récupération des données du véhicule',
                details: '',
            },
            '4x000': {
                title: 'Le module n\'a pas pu être trouvé',
                details: '',
            },
            '4x001': {
                title: 'Le champ "idFirma" est absent de la configuration',
                details: '',
            },
            '4x002': {
                title: 'L\'adresse mail de contact n\'est pas configurée.',
                details: 'Veuillez contacter le support client.',
            },
            '4x003': {
                title: 'Le champ "contactLeadSystem" est absent de la configuration',
                details: '',
            },
            '4x004': {
                title: 'Le champ "linkDisclaimer" est absent de la configuration',
                details: '',
            },
            '4x005': {
                title: 'Outil HOMEPAGE désactivé',
                details: '',
            },
            '4x006a': {
                title: 'Curseur d\'action désactivé',
                details: '',
            },
            '4x006b': {
                title: 'Vue de financement désactivée',
                details: '',
            },
            '4x006c': {
                title: 'Recherche rapide désactivée',
                details: '',
            },
            '4x006d': {
                title: 'Recherche de véhicule désactivée',
                details: '',
            },
            '4x006e': {
                title: 'Recherche rapide avancée désactivée',
                details: '',
            },
            '4x006f': {
                title: 'Curseur de véhicule désactivé',
                details: '',
            },
            '4x006g': {
                title: 'Liste des véhicules désactivé',
                details: '',
            },
            '4x006h': {
                title: 'Emplacement désactivé',
                details: '',
            },
            '4x007': {
                title: 'Hôte manquant dans l\'URL',
                details: '',
            },
            '0815x001': {
                title: 'Erreur de test goBack',
                details: '',
            },
            '0815x002': {
                title: 'Erreur de test reloadPage',
                details: '',
            },
            '0815x003': {
                title: 'Erreur de test exitError',
                details: '',
            },
        },
    },
    filter: {
        aktion: 'Véhicules d\'action',
        aktionActive: 'Véhicules en promotion uniquement',
        farbe: 'Couleur',
        hersteller: 'Constructeur',
        modell: 'Modèle',
        typ: 'Type de véhicule',
        stime: 'Durée de stockage',
        standort: 'Emplacement',
        standortMap: {
            buttonText: 'Vue de la carte',
            dialog: {
                filterButton: 'Afficher {0} véhicules',
                filterAllButton: 'Afficher tous les véhicules',
                title: 'Filtrer les Emplacement',
                selectAll: 'Sélectionner tout',
            },
        },
        aufbau: 'Carrosserie',
        aufbauten: 'Carrosseries',
        siegel: 'Sceau',
        preis: 'Prix',
        preisVon: 'Prix de',
        preisBis: 'Prix jusqu\'à',
        rate: 'Mensualité',
        rateVon: 'Mensualité de',
        rateBis: 'Mensualité jusqu\'à',
        ez: 'Première immatriculation',
        ezVon: 'Première immatriculation de',
        ezBis: 'Première immatriculation jusqu\'à',
        kategorie: 'Catégorie',
        kategorieCARAVAN: 'Caravane',
        kategorieKRAD: 'Moto',
        kategorieREISEMOBIL: 'Roulotte',
        kategorieTRANSPORTER: 'Camionnette',
        km: 'Kilométrage',
        kmVon: 'Kilométrage de',
        kmBis: 'Kilométrage jusqu\'à',
        leistung: 'Puissance immatriculation',
        leistungVon: 'Puissance immatriculation de',
        leistungBis: 'Puissance immatriculation jusqu\'à',
        motor: 'Moteur',
        getriebe: 'Boîte de vitesses',
        sofort: 'Disponible immédiatement',
        sofortActive: 'Seulement disponible immédiatement',
        tags: 'Action',
        fd: 'Services financiers',
        aus: 'Equipement',
        ausHeading: 'Sélectionner les équipements',
        volltext: 'Recherche plein texte',
        availabileDate: 'Date de disponibilité',
        asc: 'croissant',
        desc: 'descendant',
        Alle: 'Tous',
        alle: 'tous',
        from: 'à partir de',
        to: 'jusqu\'à',
        moreThan: 'plus que',
    },
    footerTextNEFZ: 'Pour plus d\'informations sur la consommation officielle de carburant et les émissions spécifiques de CO2 officielles des voitures particulières neuves, veuillez consulter le \'Guide de la consommation de carburant et des émissions de CO2 des voitures particulières neuves\', disponible gratuitement dans tous les points de vente et auprès de {0}.',
    footerTextWLTP: 'Les informations sont fournies conformément au règlement sur l\'étiquetage de la consommation d\'énergie des voitures. Les valeurs indiquées ont été déterminées selon la méthode de mesure prescrite WLTP (World Harmonised Light Vehicles Test Procedure). La consommation de carburant et les émissions de C02 d\'une voiture ne dépendent pas uniquement de l\'utilisation efficace du carburant par la voiture, mais également du style de conduite et d\'autres facteurs non techniques. Le C02 est le principal gaz à effet de serre responsable du réchauffement climatique. Un guide sur la consommation de carburant et les émissions de C02 de tous les modèles de voitures particulières neuves proposées en Allemagne peut être consulté gratuitement sous forme électronique dans tous les points de vente en Allemagne où des voitures particulières neuves sont exposées ou proposées. Le guide peut également être consulté à l\'adresse Internet suivante: {0}. Seules les émissions de C02 générées par l\'utilisation de la voiture sont indiquées. Les émissions de C02 générées ou évitées par la production et la mise à disposition de la voiture particulière ainsi que du carburant ou des sources d\'énergie ne sont pas prises en compte dans la détermination des émissions de C02 selon WLTP.',
    opening: {
        closed: 'fermé',
        openTill: 'ouvert jusqu\'à {0} heures',
        openAtToday: 'fermé, ouvre à {0} heures',
        openAtTomorrow: 'fermé, ouvre demain à {1} heures',
        openAtOtherDay: 'fermé, ouvre le {0} à {1} heures',
        verkauf: 'Vente',
        service: 'Service',
        werkstatt: 'Atelier',
    },
    time: {
        days: {
            0: 'Dimanche',
            1: 'Lundi',
            2: 'Mardi',
            3: 'Mercredi',
            4: 'Jeudi',
            5: 'Vendredi',
            6: 'Samedi',
            singular: 'jour',
            plural: 'jours',
        },
        month: {
            plural: 'mois',
            singular: 'mois',
        },
        week: {
            plural: 'semaines',
            singular: 'semaine',
        },
        year: {
            plural: 'Années',
            singular: 'Année',
        },
    },
    validation: {
        invalid: 'Non valide!',
        invalidEntry: 'Valeur non valide! Autorisé: {0}',
        maxCarPrice: 'Le prix est plus élevé que celui du véhicule',
        minPrice: 'Prix trop bas',
        required: 'Champ obligatoire',
        wholeNumber: 'Seuls les entiers sont autorisés',
    },
};

export default frFR;
