
import { deDE as MuiLocaleDeDe, enUS as MuiLocaleEnUs, frFR as MuiLocaleFrFr } from '@mui/material/locale';
import { de, enUS as en, fr } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Lang, Language } from '../_configs/types';
import { useSettingsContext } from '../context/settings';

const LANGS: Language[] = [
    {
        label: 'German',
        value: Lang.deDE,
        systemValue: MuiLocaleDeDe,
        icon: 'emojione:flag-for-germany',
        locale: de,
    },
    {
        label: 'English',
        value: Lang.enUs,
        systemValue: MuiLocaleEnUs,
        icon: 'emojione:flag-for-united-kingdom',
        locale: en,
    },
    {
        label: 'French',
        value: Lang.frFr,
        systemValue: MuiLocaleFrFr,
        icon: 'emojione:flag-for-france',
        locale: fr,
    },
];

export function getMetaLocales(lang: Lang) {
    switch (lang) {
        case Lang.frFr:
            return 'fr_FR';
        case Lang.enUs:
            return 'en_US';
        default:
            return 'de_DE';
    }
}

export default function useLocales() {
    const { t: translate } = useTranslation();
    const settings = useSettingsContext();
    const currentLang = LANGS?.find((d) => d?.value === settings?.general?.language) || LANGS[1];

    return {
        translate,
        currentLang,
        allLang: LANGS,
    };
}
