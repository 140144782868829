export default function chunkArray<Data>(arr: Data[], chunkSize: number): Data[][] {
    let i: number;
    let j: number;
    const returnArr = [];

    for (i = 0, j = arr.length; i < j; i += chunkSize) {
        returnArr.push(arr.slice(i, i + chunkSize));
    }

    return returnArr;
};
