/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { FilterOrder, FilterSort } from '../../_types/Car';
import { breakpoints } from '../../theme/breakpoints';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configSpindler: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54390,
        enable: true,
        projectName: 'Spindler',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        defaultSort: { order: FilterOrder.DESC, sort: FilterSort.Verkaufspreis },
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['sofort', 'typ', 'hersteller', 'modell', 'aufbau', 'ez', 'motor', 'getriebe', 'aus', 'preis', 'rate', 'standort', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]aufbau', '[LG]ez', 'motor', 'getriebe', 'standort', 'aus', 'preis', 'rate', 'typ', 'tags', 'sofort'],
        },
        tradeInLink: 'https://spindlerkauftdeinauto.de',
        // useLocationMap: true,
    },
    style: {
        ...configDefault.style,
        fontFamily: '"Nunito Sans", "Open Sans", "Arial", sans-serif',
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        'div[id^="button--userlike"] > div': {
                            bottom: '132px !important',
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="useContact_dialog_disclaimer"]': {
                                paddingBottom: '50px',
                            },
                        },
                    },
                },
            },
        }),
        primaryColor: '#e30713',
    },
};

export default configSpindler;
