import './locales/i18n';

import { Skeleton } from '@mui/material';
import { lazy, Suspense } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { ConfigObject } from './_configs/types';
import { Modules } from './_json/modules';
import { getAllFacets } from './_tools/axios/cars';
import { getFaarenVehicles } from './_tools/axios/faaren';
import { AppContainer } from './types';
import { createConfigObject } from './utils/texte';
import { checkUndefinedNullOrEmpty, checkUndefinedOrNull } from './utils/tools';

const Entry = lazy(() => import('./entry' /* webpackChunkName: "Entry" */));
const LoadingComponent = lazy(() => import('./_tools/LoadingComponent' /* webpackChunkName: "LoadingComponent" */));

const reactContent: Partial<Record<Modules, Root>> = {};
const timers: Partial<Record<Modules, NodeJS.Timeout>> = {};

async function init(settings: ConfigObject) {
    if (settings?.general?.debug) console.log('entry - Initializing React-Components');
    try {
        const containerIds: AppContainer[] = [
            { id: 'ahdAktion', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Aktionsslider, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdErweiterteSchnellsuche', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.ErweiterteSchnellsuche, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdFahrzeugliste', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Fahrzeugliste, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdFahrzeugslider', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Fahrzeugslider, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdFahrzeugsuche', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Fahrzeugsuche, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdFinanzierung', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Finanzierungsansicht, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdInstallDocumentationSite', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Install, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdPrint', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Print, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdConfigListing', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.ConfigListing, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdSchnellsuche', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Schnellsuche, useOldRouter: settings?.general?.useOldRouter } },
            { id: 'ahdStandorte', allFacets: undefined, appProps: { allFaarenVehicles: undefined, module: Modules?.Standorte, useOldRouter: settings?.general?.useOldRouter } },
        ];

        containerIds.map(function (container) {
            const domContainer = document.getElementById(container?.id);
            if (!checkUndefinedOrNull(domContainer)) {
                reactContent?.[container?.appProps?.module]?.unmount();
                reactContent[container?.appProps?.module] = createRoot(domContainer);
                timers[container?.id] = setTimeout(function () {
                    reactContent?.[container?.appProps?.module]?.render(<Skeleton variant="rounded" sx={{ height: 65, width: '100%' }} />);
                }, 150);
            }
        });

        const allFacets = await getAllFacets(settings?.general?.idFirma, settings?.general?.language).then(function ({ data }) {
            return data?.facets || {};
        });
        const allFaarenVehicles = settings?.api?.faaren?.enabled && !checkUndefinedNullOrEmpty(settings?.api?.faaren?.apiKey) ? (await getFaarenVehicles(settings?.api?.faaren?.apiKey)) : [];

        Object.keys(timers).map(function (key) { clearTimeout(timers[key]); });

        containerIds.map(function (container) {
            const domContainer = document.getElementById(container?.id);
            if (!checkUndefinedOrNull(domContainer)) {
                timers[container?.id] = setTimeout(function () {
                    reactContent?.[container?.appProps?.module]?.render(
                        <Suspense fallback={<LoadingComponent />}>
                            <Entry allFacets={allFacets} appProps={{ ...container?.appProps, allFaarenVehicles }} id={container?.id} />
                        </Suspense>
                    );
                }, 150);
            }
        });
    } catch (e) {
        console.error('Failed to initialize React-Components:', e);
    }
}

init(createConfigObject(undefined, undefined, false));
