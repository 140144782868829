/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';
import { FilterOrder, FilterSort } from '../../_types/Car';

const configReim: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54090,
        enable: true,
        projectName: 'Reim Automobile',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        defaultSort: { order: FilterOrder.DESC, sort: FilterSort.Verkaufspreis },
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            pageSize: 9,
        },
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        fontFamily: '"__Nunito_Sans_63b6cf", "__Nunito_Sans_Fallback_63b6cf", "Helvetica", "Arial", "sans-serif"',
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.05em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 900,
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                },
            },
        }),
        primaryColor: '#c9070a',
    },
};

export default configReim;
