/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { FilterOrder, FilterSort } from '../../_types/Car';

const configLiliensiek: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 13890,
        enable: true,
        metaTitleSuffix: 'Autohaus Liliensiek',
        projectName: 'Autohaus Liliensiek',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 8,
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutzerklaerung',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    api: {
        ...configDefault.api,
        autoUncle: {
            ...configDefault.api.autoUncle,
            id: 'HSq7b1ATbT',
            origin: 'liliensiek.de',
            priceRating: true,
            sourceName: 'Ah-liliensiek.de',
            tradeIn: true,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        idExposee: 1572,
        defaultSort: { order: FilterOrder.DESC, sort: FilterSort.Hersteller },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Muli", "Open Sans", "Arial", sans-serif',
        fontSize: 14,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'h2::before': {
                        content: 'none',
                    },
                    'div.UseContact_ContactTextButtonContainer': {
                        borderRadius: '5px',
                    },
                    '.wpb_text_column :last-child, .wpb_text_column p:last-child': { // important, otherwise lots of prices, car details and labels cut off
                        overflowX: 'unset !important',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'gap': '2px',
                            'marginTop': '2px',
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                    label: {
                        fontSize: '12px',
                        height: '11px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '2px',
                        textTransform: 'none',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'borderRadius': '5px',
                            '&:hover': {
                                backgroundColor: '#0068aa',
                                color: '#fff !important',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            borderRadius: '5px',
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '5px',
                            '&#detailview2_button_exposee': {
                                height: '45px !important',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"]': { // fix scrollbars sideway
                                maxWidth: '100% !important',
                            },
                            '&[data-testid="CarDetailsGrid_Container"] > div.MuiStack-root.MuiGrid2-root:nth-child(even)': { // fix border at right side of grid
                                borderRight: 'none !important',
                            },
                            '&[data-testid="CarDetailsGrid_Container"] > div.MuiStack-root.MuiGrid2-root:nth-last-child(-n + 2)': { // fix double divider line at bottom of grid
                                borderBottom: 'none !important',
                            },
                            '&[data-testid="CarDetailsGrid_Container"] p.MuiTypography-body1': {
                                color: '#212b36',
                            },
                            '&[data-testid="CarDetailsGrid_ContainerGridVerbrauch"]': { // fix scrollbars sideway
                                maxWidth: '100% !important',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid^="carTile_"]:hover': { // fix colored icons on hover
                                color: 'rgb(33, 43, 54) !important',
                            },
                            '&[data-testid="CalcFdSummary_Card"] .MuiTypography-h5, &[data-testid="CalcFdSummary_Card"] .MuiTypography-h6': {
                                color: '#fff',
                            },
                            '&[data-testid="AusstattungLocationInfo_Card"] a.MuiLink-root.MuiLink-underlineHover.MuiButton-text > *:hover': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarPriceGrid_Container"] p.MuiTypography-h5, &[data-testid="CarPriceGrid_Container"] p.MuiTypography-h6': {
                                color: '#212b36',
                                textTransform: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '#222222',
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '#222222',
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '#222222',
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '#222222',
                    fontFamily: '"Montserrat", ' + '<fontFamily>',
                    textTransform: 'uppercase',
                },
            },
        }),
        primaryColor: '#0099da',
    },
};

export default configLiliensiek;
