import { Filter, ViewOptions, ViewOptionsView } from '../_types/Car';

export const standardFilter: Filter = {
    sort: null,
    order: null,
    fd: [],
    idFirma: [],
    Standort: [],
    hersteller0: -100,
    modell0: -100,
    aufbau: -100,
    aufbauten: [],
    siegel: -100,
    akttypList: [],
    Preis: null,
    EZ: null,
    Leistung: null,
    kategorie: [],
    KM: null,
    Rate: null,
    MotorG: -100,
    motoren: [],
    Getriebe: -100,
    sofort: null,
    ia: [],
    tags: '-100',
    page: 1,
    pageSize: 18,
    freier_text: '',
    Farben: [],
    aktion: null,
};

export const standardViewOptions: ViewOptions = {
    advanced: false,
    car: undefined,
    view: ViewOptionsView.Grid,
};
